import React from 'react';
import { PageProps } from 'gatsby';
import { MainLayout } from '../MainLayout';
import 'basiclightbox/dist/basicLightbox.min.css';
import { MarkdownPageContent } from '../../components/common/MarkdownPageContent';
import { IAlternativesQuery } from '../../queries/alternatives/types';
import styles from './styles.module.scss';
import { TLink } from '../../i18n/TLink';

type IData = {
  page: {
    frontmatter: {
      title: string;
      description: string;
      subtitle: string;
      tags: [string];
    };
    fields: { pageLang: string };
    html: string;
  };
} & IAlternativesQuery;

type IProps = PageProps<
  IData,
  {
    blogSlug: string;
    blogName: string;
    title: string;
    content: string;
    seoPost: {
      seoTitle: string;
      seoDescription: string;
      seoKeywords: string;
    };
    locale: string;
  }
>;
export default ({
  pageContext: { blogSlug, blogName, title, seoPost, content, locale },
}: IProps) => (
  <MainLayout
    locale={locale}
    title={seoPost?.seoTitle}
    description={seoPost?.seoDescription}
    keywords={seoPost?.seoKeywords}
  >
    <div className="content">
      <div className={styles.breadcrumbs}>
        <TLink to={`/blog/${blogSlug}`}>{blogName}</TLink>
        <span> / {title}</span>
      </div>

      <MarkdownPageContent title={title} subtitle="" html={content} />
    </div>
  </MainLayout>
);
