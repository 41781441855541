/* eslint-disable no-param-reassign */
/* eslint-disable no-useless-escape */
/* eslint-disable no-return-assign */
/* eslint-disable consistent-return */
import React, { FC, useCallback, useEffect, useRef } from 'react';
import classnames from 'classnames';
import * as basicLightbox from 'basiclightbox';
import styles from './styles.module.scss';
import { usePathPrefix } from '../../../constants/hooks';

interface IProps {
  title: string;
  subtitle: string;
  html: string;
}

const MarkdownPageContent: FC<IProps> = ({ title, subtitle, html }) => {
  const ref = useRef<HTMLDivElement>(null);
  const prefix = usePathPrefix();

  const omImageClick = useCallback(
    (event) => {
      basicLightbox.create(`<img src="${event.target.src}" />`).show();
    },
    [basicLightbox]
  );

  useEffect(() => {
    if (!ref || !ref.current) return;

    const gal = ref.current!.querySelector('.gallery');
    if (!gal) return;

    const images = Array.from(
      gal.querySelectorAll('img')
    ) as HTMLImageElement[];
    if (!images || !images.length) return;

    images.forEach((image) => image.addEventListener('click', omImageClick));

    return () =>
      images.forEach((image) =>
        image.removeEventListener('click', omImageClick)
      );
  }, [ref, omImageClick]);

  useEffect(() => {
    if (!prefix || !ref?.current) return;

    const images = Array.from(
      ref.current.querySelectorAll('img')
    ) as HTMLImageElement[];

    images.forEach(
      (image) =>
        (image.src = image.src.replace(
          /^(https?:\/\/[^\/]+)(\/.*)$/,
          `$1${prefix}$2`
        ))
    );
  }, [ref, prefix]);

  const withPrefixImg = html.replace(
    new RegExp('<img src="([^"]*)"', 'gm'),
    `<img src="${process.env.GATSBY_STRAPI_API_URL}$1"`
  );

  return (
    <div className={classnames(styles.page)} ref={ref}>
      {(title || subtitle) && (
        <div className="section">
          {title && <h1>{title}</h1>}
          {subtitle && <p>{subtitle}</p>}
        </div>
      )}

      <div
        dangerouslySetInnerHTML={{ __html: withPrefixImg }}
        className="section"
      />
    </div>
  );
};

export { MarkdownPageContent };
